<template>
  <section class="dashboard">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Facturas Anuladas</h4>
            <b-table
              responsive
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <!-- A virtual composite column -->
              <template #cell(fecha)="data">
                {{ moment(data.fecha).format("DD/MM/YYYY hh:mm A") }}
              </template>
            </b-table>
            <p>
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length / perPage"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "cancelled-sales",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "descripcion",
          label: "Descripcion",
          sortable: true,
        },
        {
          key: "cantidad",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "monto",
          label: "Monto",
          sortable: true,
        },
        {
          key: "vendedor",
          label: "Quien Autorizo?",
          sortable: true,
        },
        {
          key: "fecha",
          label: "Fecha ",
          sortable: true,
        },
        {
          key: "mesa",
          label: "Mesa",
          sortable: true,
        },
        {
          key: "sala",
          label: "Sala",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  created() {
    this.getTransactions();
    this.$store.dispatch("setEvent", { name: "open_cancelled" });
    
  },
  computed: {
    ...mapGetters(["getOpenDate", "getCloseDate"]),
  },
  methods: {
    getTransactions() {
      axios("/api/reports/transacts/cancelled", {
        params: {
          fromDate: this.getOpenDate,
          toDate: this.getCloseDate,
        },
      }).then((res) => {
        this.items = res.data.data;
      });
    },
    numeral,
    moment,
  },
  watch: {
    getOpenDate() {
      this.getTransactions();
    },
  },
};
</script>